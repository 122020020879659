import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { lighten } from '@mui/material/styles'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import moment from 'moment'

import _Base from '../_Base'
import EventFormMap from '../EventFormMap/EventFormMap'
import EventFormChart from '../EventFormChart/EventFormChart'
import GroupBox from '../GroupBox/GroupBox'
import AppState from '../../managers/AppStateManager'
import Util from '../../utils/Util'
import { C_MODE, 
    C_DEFAULTS } from '../../variables/common'

const styles = theme => ({
    root: {
        fontSize: '100%',
        height: '100%',
        padding: '10px 10px 0px 10px',
    },
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    titleText: {
        marginLeft: -10,
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.85rem',
        lineHeight: 1,
        transform: 'scale(0.75)'
    },
    rowItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 5
    },
    lineItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelText: {
        fontSize: '0.85rem',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    labelValue: {
        fontSize: '0.85rem',
        fontWeight: 500
    },
    cardHeaderTitle: {
        textAlign: 'left',
        fontSize: '0.9em',
    },
    rangeControlContainer: {
        paddingTop: 5,
        paddingBottom: 5,
        marginBottom: 5,
        borderTop: '1px solid #e6e6e6',
        borderBottom: '1px solid #e6e6e6',
        height: 70,
    },
    rangeControl: {
        flex: 1,
        display: 'flex',
        padding: '0 20px 0 10px',
        minWidth: 240,
        '& p.start': {
            marginTop: -3,
            marginRight: 10,
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '1rem',
            lineHeight: 1,
            transform: 'translate(0, 1.5px) scale(0.75)'
        },
        '& p.end': {
            marginTop: -3,
            marginLeft: 10,
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '1rem',
            lineHeight: 1,
            transform: 'translate(0, 1.5px) scale(0.75)'
        }
    },
    platformLabel: {
        padding: 3,
        borderRadius: 10,
        border: '1px solid gray',
        color: 'white',
        backgroundColor: 'gray',
        fontSize: '0.8em',
    }
})

class EventFormAttachmentItem extends _Base {

    handleRemoveEvent = () => {
        const { attachmentIndex } = this.props
        AppState.removeAttachment(attachmentIndex)
    }

    extractData = () => {
        const { eventAttachment } = this.props
        const eventData = this.props.getFormData()
        const netCdfFilename = eventAttachment.netCdfFilename
        const title = eventAttachment.title
        const startTime = moment.utc(eventAttachment.startTime)
        const endTime = moment.utc(eventAttachment.endTime)
        const duration = eventAttachment.duration
        const location = eventAttachment.location
        const geoData = eventAttachment.geoData

        return {
            _id: eventAttachment._id,
            tempId: eventAttachment.tempId,
            netCdfFilename: netCdfFilename,
            title: title,
            platformId: eventAttachment.platformId,
            startTime: startTime,
            endTime: endTime,
            duration: duration,
            location: location,
            geoData: geoData,
            eventData: eventData
        }
    }

    constructor(props) {
        super(props)
        const data = this.extractData()
        this.state = {
            eventAttachment: props.eventAttachment,
            ...data
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (!Util.isEqualByValue(previousProps.eventAttachment, this.props.eventAttachment)) {
            const data = this.extractData()
            this.setState({
                eventAttachment: this.props.eventAttachment,
                ...data
            })
        } else if ((previousProps.eventRefreshAttachments && this.props.eventRefreshAttachments) && !Util.isEqualByValue(previousProps.eventRefreshAttachments, this.props.eventRefreshAttachments)) {
            const { _id } = this.state
            let tempId;
            let geoData;
            if (_id && _id.length) {
                tempId = _id[0]
                geoData = this.props.eventRefreshAttachments[tempId]
            }
            else {
                geoData = this.props.eventRefreshAttachments[_id]
            }
            const eventData = this.props.getFormData()

            if (geoData) {
                this.setState({
                    geoData: geoData,
                    eventData: eventData
                })
            }
        }
    }

    render() {
        const { classes, attachmentIndex, attachmentsCount, showDownload, isManuallyGenerated, status } = this.props
        const { _id, 
            tempId,
            platformId,
            startTime,
            endTime,
            duration,
            location,
            geoData,
            eventData } = this.state;
        
        let {
            netCdfFilename
        } = this.state;

        const longitude = location.coordinates[0]
        const latitude = location.coordinates[1]
        const attachmentId = _id ? _id : tempId
        const counterLabel = `${attachmentIndex + 1} / ${attachmentsCount}`
        let filenames = []
        if (typeof(netCdfFilename) === 'string') {
            netCdfFilename = [netCdfFilename];
        }
        netCdfFilename.forEach((netcdf) => {
            const tmp = netcdf.split('_')
            tmp.shift()
            filenames.push(tmp.join('_'))
        })
        const joinedFilename = filenames.length > 1 ? filenames.join(", ") : filenames[0];

        const groupTitleNode = (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ margin: 0, display: 'flex', alignItems: 'center' }} title={joinedFilename}>
                    <div style={{display: 'flex', flex: 'none', padding: 6, backgroundColor: 'lightgray'}}>
                        <div className={classes.platformLabel}>{platformId}</div>
                    </div>
                    <div style={{ marginLeft: 5, display: 'flex', alignItems: 'center', padding: 5 }}>
                        <Typography>{joinedFilename}</Typography>
        {attachmentsCount > 1 && <Typography style={{marginRight: 10, marginLeft: 10}}>({counterLabel})</Typography>}
                    </div>
                </div>
                {AppState.mode !== C_MODE.C_VIEW_MODE && isManuallyGenerated &&
                    <IconButton style={{ padding: 2 }} onClick={this.handleRemoveEvent} size="large">
                        <DeleteIcon />
                    </IconButton>
                }
                {netCdfFilename.map((n, idx) => {
                    const tmp = n.split('_') // TODO: handle multiple netCdfFilenames
                    tmp.shift()
                    const filename = tmp.join('_') 
                    const csvUrl = `${window.BOLIDES_APP_CSV_SERVER_URL}${n}.csv`
                    const csvFile = filename + '.csv'
                    if (status === "PUBLISHED") {
                        return (
                            <a key={idx} href={csvUrl} target="_blank" rel="noopener noreferrer" title={csvFile} style={{ textDecoration: 'none' }}>
                                <IconButton style={{ padding: 5, marginRight: 5}} size="large">
                                    <CloudDownloadIcon className={classes.icon} />
                                </IconButton>
                            </a>
                        );
                    }
                })
                }
            </div>
        )
 
        return (
            <div className={classes.root}>
                <div className={classes.container}>

                    <GroupBox withHeader={true} groupTitle={groupTitleNode} flexible={true} cardHeaderTitle={classes.cardHeaderTitle}>

                        <div className={classes.rowItem} style={{ backgroundColor: lighten('#f5f5f5', 0.9), marginTop: 5, marginBottom: 5, flexWrap: 'wrap' }}>
                            <div className={classes.lineItem} style={{ flex: 'none', marginLeft: 5, whiteSpace: 'nowrap' }}>
                                <Typography className={classes.labelText}>Start Time:</Typography>
                                <Typography className={classes.labelValue}>{startTime.format(C_DEFAULTS.C_DATE_FORMAT_STRING)}</Typography>
                            </div>
                            <div className={classes.lineItem} style={{ flex: 'none', marginLeft: 5, whiteSpace: 'nowrap' }}>
                                <Typography className={classes.labelText}>End Time:</Typography>
                                <Typography className={classes.labelValue}>{endTime.format(C_DEFAULTS.C_DATE_FORMAT_STRING)}</Typography>
                            </div>
                            <div className={classes.lineItem} style={{ flex: 'none', marginLeft: 5, whiteSpace: 'nowrap' }}>
                                <Typography className={classes.labelText}>Duration:</Typography>
                                <Typography className={classes.labelValue}>{duration.toFixed(3)} seconds</Typography>
                            </div>
                            <div className={classes.lineItem} style={{ flex: 'none', marginLeft: 5, whiteSpace: 'nowrap' }}>
                                <Typography className={classes.labelText}>Latitude:</Typography>
                                <Typography className={classes.labelValue}>{latitude.toFixed(1)}</Typography>
                            </div>
                            <div className={classes.lineItem} style={{ flex: 'none', marginLeft: 5, whiteSpace: 'nowrap' }}>
                                <Typography className={classes.labelText}>Longitude:</Typography>
                                <Typography className={classes.labelValue}>{longitude.toFixed(1)}</Typography>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', overflow: 'hidden' }}>
                            <EventFormMap attachmentId={attachmentId} geoData={geoData} eventData={eventData} />
                            <EventFormChart chartType="TrajectoryChart" attachmentId={attachmentId} geoData={geoData} />
                        </div>
                        <EventFormChart chartType="EnergyChart" attachmentId={attachmentId} geoData={geoData} />
                    </GroupBox>
                </div>
            </div>
        )
    }
}

EventFormAttachmentItem.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EventFormAttachmentItem)
